import React from 'react'
import { Global, css } from '@emotion/core'
import emotionNormalize from 'emotion-normalize'

const StyleNormalizer = () => (
  <>
    <Global
      styles={css`
        ${emotionNormalize}
      `}
    />
    <Global
      styles={css`
        * {
          box-sizing: border-box;
          outline: none;
        }
        html,
        body {
          padding: 0;
          margin: 0;
          background: white;
          min-height: 100%;
          font-family: 'Open Sans', sans-serif;
        }
        a {
          text-decoration: none;
        }
        ul,
        ol {
          padding: 0;
          margin: 0;
          list-style: none;
        }
      `}
    />
  </>
)

export default StyleNormalizer

import { graphql, useStaticQuery } from 'gatsby'
import formatNodes from '../utils/formatNodes'

const useFooter = () => {
  const nodes = useStaticQuery(
    graphql`
      fragment FluidImage on File {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      query {
        piccollageWhiteLogo: file(
          relativePath: { eq: "logo_piccollage_white.png" }
        ) {
          childImageSharp {
            fixed(width: 213, height: 34) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  return formatNodes(nodes)
}

export default useFooter

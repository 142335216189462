import React, { useState, useCallback } from 'react'
import { Link } from 'gatsby'
import LinkImage from 'gatsby-image'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { H4, pStyle } from '../../styleElements'
import Icon from '../Icon'
import MobileMenu from '../MobileMenu'
import path from '../../utils/getPathName'
import mq from '../../utils/mediaQuery'
import useHeader from '../../queries/useHeader'

export const menuItems = ['products', 'careers', 'about']
export const aboutUrl =
  'https://piccollage.notion.site/About-Us-41c11b25f0384a3f95bbb21ba2c9bd78'

const Header = () => {
  const nodes = useHeader()

  const [isMobileMenuOpen, setMobileMenu] = useState(false)

  const handleOpenMobileMenu = useCallback(() => setMobileMenu(true), [])
  const handleCloseMobileMenu = useCallback(() => setMobileMenu(false), [])

  const currentPath = path.getPathName()

  return (
    <Root>
      <Container>
        <LinkStyled to="/">
          <LinkImage
            fixed={nodes.piccollageLogo}
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </LinkStyled>
        <Nav>
          <NavContainer>
            {menuItems.map(item => {
              if (item === 'about') {
                const isActive = false
                return (
                  <NavItem key={item} isActive={isActive}>
                    <NavA href={aboutUrl}>{item.toUpperCase()}</NavA>
                  </NavItem>
                )
              } else {
                const itemPath = `/${item}`
                const isActive = currentPath.includes(itemPath)
                return (
                  <NavItem key={item} isActive={isActive}>
                    <NavLink to={itemPath}>{item.toUpperCase()}</NavLink>
                  </NavItem>
                )
              }
            })}
          </NavContainer>
        </Nav>
        <HamburgerMenu>
          <Icon
            name="menu_hamburger"
            size={24}
            onClick={handleOpenMobileMenu}
          />
        </HamburgerMenu>
      </Container>
      {isMobileMenuOpen && <MobileMenu closeMenu={handleCloseMobileMenu} />}
    </Root>
  )
}

const Root = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 52px;
  padding: 0 20px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.21);
  border-bottom: 1px solid transparent;
  background-color: white;
  z-index: 2;

  ${mq.md} {
    height: 60px;
    padding: 0 20px;
  }
`

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mq.md} {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 3vw;
    justify-content: space-between;
  }
`

const LinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vw 0;
`

const HamburgerMenu = styled.div`
  ${mq.md} {
    display: none;
  }
`

const Nav = styled.nav`
  display: none;
  ${mq.md} {
    margin-left: 36px;
    display: block;
  }
`

const NavContainer = styled.ul`
  display: flex;
  align-items: center;
`

const activeNavStyle = props =>
  css`
    ${NavLink}, ${NavA} {
      color: ${props.theme.color.primary1};
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: ${props.theme.color.primary1};
      z-index: 1;
    }
  `

const NavItem = styled.li`
  position: relative;

  &:not(:first-of-type) {
    margin-left: 36px;
  }

  ${props => props.isActive && activeNavStyle}

  &:hover {
    ${activeNavStyle}
  }
`

export const NavLink = styled(Link)`
  ${pStyle}
  font-weight: ${props => props.theme.fontWeight.semiBold};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

export const NavA = styled.a`
  ${pStyle}
  font-weight: ${props => props.theme.fontWeight.semiBold};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

export default Header

import { globalHistory } from '@reach/router'

const getPathName = () => globalHistory.location.pathname

const getIsCareesPage = () =>
  getPathName() === '/careers' || getPathName() === '/careers/'

export default {
  getIsCareesPage,
  getPathName
}

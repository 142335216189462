import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import Style from '../Style'
import Header from '../Header'
import Footer from '../Footer'

import mq from '../../utils/mediaQuery'

const Layout = ({ children }) => {
  return (
    <Style>
      <Root>
        <Header />
        <Main>{children}</Main>
        <Footer />
      </Root>
    </Style>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Main = styled.main`
  flex-grow: 1;
  padding-top: 52px;
  background-color: ${props => props.theme.color.gray4};

  ${mq.md} {
    padding-top: 60px;
  }
`

export default Layout

import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import Icon from '../Icon'
import { SmallText } from '../../styleElements'

const BackToTop = props => {
  const scrollStep = () => {
    if (window.pageYOffset > 0) {
      window.requestAnimationFrame(scrollStep)
    }

    window.scroll(0, window.pageYOffset - props.scrollStepInPx)
  }

  const scrollToTop = () => {
    window.requestAnimationFrame(scrollStep)
  }

  return (
    <Root {...props} onClick={scrollToTop}>
      <Arrow name="next_white" size={24} />
      <Label>TOP</Label>
    </Root>
  )
}

BackToTop.propTypes = {
  onClick: PropTypes.func,
  scrollStepInPx: PropTypes.number
}

BackToTop.defaultProps = {
  onClick: () => {},
  scrollStepInPx: 100
}

const Root = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 2px solid ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.primary1};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const Arrow = styled(Icon)`
  flex-shrink: 0;
  transform: rotate(-90deg) translatex(-3px);
`

const Label = styled(SmallText)`
  color: ${props => props.theme.color.white};
  transform: translateY(-7px);
`

export default BackToTop

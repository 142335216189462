import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import path from '../../utils/getPathName'
import getCdnImage from '../../utils/getCdnImage'
import isProduction from '../../utils/env'

function SEO({ title }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            description
            author
            ogImage
          }
        }
      }
    `
  )

  const siteTitle = get(
    data,
    'site.siteMetadata.title',
    'please check title in gatsby-config.js'
  )

  const description = get(
    data,
    'site.siteMetadata.description',
    'please check description in gatsby-config.js'
  )

  const ogImage = get(
    data,
    'site.siteMetadata.ogImage',
    'https://user-images.githubusercontent.com/5327305/76199516-f9f5b780-622a-11ea-8f84-72fedcb808d7.jpg'
  )

  const ogData = {
    'og:title': path.getIsCareesPage() ? 'PicCollage is hiring' : siteTitle,
    'og:image': path.getIsCareesPage()
      ? getCdnImage('/website/jobs/careers-team-photo.png') // TODO: need to also update this photo.
      : ogImage,
    'og:description': path.getIsCareesPage()
      ? 'PicCollage 拼貼趣 是一支擁抱自由創造、擁抱差異與多元文化，致力於打造『開放、學習、分享』的工作環境。 我們使用友善的設計及嚴謹的軟體技術， 也積極開發 3D、AR/VR、Machine Learning 相關科技，讓人們用創意拼貼生活中值得...'
      : description,
    'og:type': 'website'
  }

  return (
    <Helmet title={title} titleTemplate={`%s | ${siteTitle}`}>
      <html lang="en" />
      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
      />
      <meta name="description" content={ogData['og:description']} />

      <meta property="og:title" content={ogData['og:title']} />
      <meta property="og:description" content={ogData['og:description']} />
      <meta property="og:image" content={ogData['og:image']} />
      <meta property="og:type" content={ogData['og:type']} />
      {isProduction && (
        <link rel="canonical" href="https://cardinalblue.com/" />
      )}
      {!isProduction && <meta name="robots" content="noindex,nofollow" />}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en'
}

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired
}

export default SEO

import { graphql, useStaticQuery } from 'gatsby'
import formatNodes from '../utils/formatNodes'

const useHeader = () => {
  const nodes = useStaticQuery(
    graphql`
      fragment FluidImage on File {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      query {
        piccollageLogo: file(relativePath: { eq: "logo_piccollage.png" }) {
          childImageSharp {
            fixed(width: 150, height: 36) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  return formatNodes(nodes)
}

export default useHeader

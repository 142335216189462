import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Line = ({ color, full, ...rest }) => {
  return <Root color={color} full={full} {...rest} />
}

Line.propTypes = {
  color: PropTypes.string,
  full: PropTypes.bool
}

Line.defaultProps = {
  color: '',
  full: false
}

const Root = styled.div`
  width: ${props => (props.full ? '100%' : '100px')};
  height: 1px;
  background-color: ${props => props.color || props.theme.color.gray1};
`

export default Line

import React from 'react'
import PropTypes from 'prop-types'

import StyleNormalizer from '../../components/StyleNormalize'

const Style = ({ children }) => {
  return (
    <>
      <StyleNormalizer />
      {children}
    </>
  )
}

Style.propTypes = {
  children: PropTypes.node.isRequired
}

export default Style

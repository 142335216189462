import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { H4 } from '../../styleElements'
import { aboutUrl, menuItems, NavA, NavLink } from '../Header'
import Icon from '../Icon'

import path from '../../utils/getPathName'
import mq from '../../utils/mediaQuery'

const MobileMenu = ({ closeMenu }) => {
  const currentPath = path.getPathName()

  return (
    <Root>
      <Header>
        <Link to="/">
          <Title>PicCollage Company</Title>
        </Link>
        <HumberMenu name="menu_close" size={24} onClick={closeMenu} />
      </Header>
      <Nav>
        <NavContainer>
          {menuItems.map(item => {
            if (item === 'about') {
              const isActive = false
              return (
                <NavItem key={item} isActive={isActive}>
                  <NavA href={aboutUrl}>{item.toUpperCase()}</NavA>
                </NavItem>
              )
            } else {
              const itemPath = `/${item}`
              const isActive = currentPath.includes(itemPath)
              return (
                <NavItem key={item} isActive={isActive}>
                  <NavLink to={itemPath}>{item.toUpperCase()}</NavLink>
                </NavItem>
              )
            }
          })}
        </NavContainer>
      </Nav>
    </Root>
  )
}

MobileMenu.propTypes = {
  closeMenu: PropTypes.func.isRequired
}

const Root = styled.article`
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.color.white};
  z-index: 1;
`

const Header = styled.header`
  height: 52px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.color.gray2};
`

const Title = styled(H4)`
  color: ${props => props.theme.color.primary1};
`

const Nav = styled.nav`
  padding: 0 20px;
`

const NavContainer = styled.ul`
  display: flex;
  flex-direction: column;
`

const activeNavStyle = props =>
  props.isActive &&
  css`
    ${NavLink} {
      color: ${props.theme.color.primary1};
    }
  `

const NavItem = styled.li`
  height: 67px;
  border-bottom: 1px solid ${props => props.theme.color.gray2};
  padding-left: 18px;
  ${activeNavStyle}
`

const HumberMenu = styled(Icon)`
  ${mq.md} {
    display: none;
  }
`

export default MobileMenu
